body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  font-family: "Poppins", sans-serif;
}
.boot_time{
  font-weight: 600;
  font-style: normal;
}
.boot_time_span{
  font-weight: 400;
  font-style: italic;
  font-size: 15px;
}

.animation_container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}